import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#2c2f78",
        secondary: "#b84e91",
        accent: "#2c2f78",
        error: "#FF0048",
        info: "#b84e91",
        success: "#b84e91",
        warning: "#ED7D31",
        leftPanel: "#2c2f78",
        rightPanel: "#ffffff",
        textColor: "#000000",
        textColorLeft: "#ffffff",
        spec: "#003895",
      },
      dark: {
        primary: "#489E99",
        secondary: "#ED7D31",
        accent: "#489E99",
        error: "#FF0048",
        info: "#ED7D31",
        success: "#ED7D31",
        warning: "#ED7D31",
        leftPanel: "#489E99",
        rightPanel: "#000000",
        textColor: "#ffffff",
        textColorLeft: "#ffffff",
        spec: "#FFEA00",
      },
    },
  },
});
